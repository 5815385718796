import union from 'lodash.union';
import { PRODUCT_CODES, SYNTHETIC_PRODUCT_CODES } from '_constants/products';
import { PROCESSING_CODES } from '_constants/processing';

export const createProductNameMap = (products) => {
  return products.reduce((res, { code, customerName }) => {
    res[code] = customerName;

    if (code === PRODUCT_CODES.incEIN) {
      res[code] = 'Employer Identification Number';
    }

    return res;
  }, {});
};

export const createProductCategoryMap = (products) => {
  return products.reduce((res, { category, code }) => {
    res[code] = category;
    return res;
  }, {});
};

export const getFullSetOfProducts = ({
  activeProductCode,
  processingCode,
  noBundles = false,
  state,
}) => {
  const DEFAULT_MAP = {
    [PRODUCT_CODES.incLLC]: {
      [PROCESSING_CODES.gold]: [
        PRODUCT_CODES.incLLC,
        PRODUCT_CODES.incRegisteredAgent,
        PRODUCT_CODES.incOperatingAgreement,
        PRODUCT_CODES.incEIN,
        PRODUCT_CODES.incBankingResolution,
        PRODUCT_CODES.incBusinessLicense,
        PRODUCT_CODES.incAnnualCompliance,
        PRODUCT_CODES.incRushProcessing,
      ],
      [PROCESSING_CODES.platinum]: [
        PRODUCT_CODES.incLLC,
        PRODUCT_CODES.incRegisteredAgent,
        PRODUCT_CODES.incOperatingAgreement,
        PRODUCT_CODES.incEIN,
        PRODUCT_CODES.incBankingResolution,
        PRODUCT_CODES.incBusinessLicense,
        PRODUCT_CODES.incAnnualCompliance,
        PRODUCT_CODES.incSCorp,
        PRODUCT_CODES.incRushProcessing,
      ],
      [PROCESSING_CODES.diamond]: [
        PRODUCT_CODES.incLLC,
        PRODUCT_CODES.incRegisteredAgent,
        PRODUCT_CODES.incBusinessLicense,
        PRODUCT_CODES.incOperatingAgreement,
        PRODUCT_CODES.incEIN,
        PRODUCT_CODES.incBankingResolution,
        PRODUCT_CODES.incAnnualCompliance,
        PRODUCT_CODES.incSCorp,
        PRODUCT_CODES.incCertificateOfGoodStanding,
        PRODUCT_CODES.incSameDayProcessing,
      ],
    },
  };

  const NO_BUNDLES_MAP = {
    [PRODUCT_CODES.incLLC]: {
      [PROCESSING_CODES.gold]: [
        PRODUCT_CODES.incLLC,
        PRODUCT_CODES.incRegisteredAgent,
        PRODUCT_CODES.incBusinessLicense,
        PRODUCT_CODES.incAnnualCompliance,
        PRODUCT_CODES.incRushProcessing,
      ],
      [PROCESSING_CODES.platinum]: [
        PRODUCT_CODES.incLLC,
        PRODUCT_CODES.incRegisteredAgent,
        PRODUCT_CODES.incBusinessLicense,
        PRODUCT_CODES.incAnnualCompliance,
        PRODUCT_CODES.incSCorp,
        PRODUCT_CODES.incRushProcessing,
      ],
      [PROCESSING_CODES.diamond]: [
        PRODUCT_CODES.incLLC,
        PRODUCT_CODES.incRegisteredAgent,
        PRODUCT_CODES.incBusinessLicense,
        PRODUCT_CODES.incAnnualCompliance,
        PRODUCT_CODES.incSCorp,
        PRODUCT_CODES.incCertificateOfGoodStanding,
        PRODUCT_CODES.incSameDayProcessing,
      ],
    },
  };

  const MAP = noBundles ? NO_BUNDLES_MAP : DEFAULT_MAP;

  const productsSet = MAP[activeProductCode]
    ? MAP[activeProductCode][processingCode] || []
    : [];

  const productsSetByState = productsSet.filter((code) => {
    if (
      code === PRODUCT_CODES.incAnnualCompliance &&
      ['AL', 'MO'].includes(state)
    ) {
      return false;
    }

    return true;
  });

  return productsSetByState;
};

export const getProductsFromBankingBundle = ({
  activeProductCode,
  syntheticProductCode,
}) => {
  const MAP = {
    [PRODUCT_CODES.incLLC]: {
      [SYNTHETIC_PRODUCT_CODES.incOAAndEINAndBR]: [
        PRODUCT_CODES.incEIN,
        PRODUCT_CODES.incOperatingAgreement,
        PRODUCT_CODES.incBankingResolution,
      ],
      [SYNTHETIC_PRODUCT_CODES.incOAAndEIN]: [
        PRODUCT_CODES.incEIN,
        PRODUCT_CODES.incOperatingAgreement,
      ],
      [PRODUCT_CODES.incEIN]: [PRODUCT_CODES.incEIN],
    },
  };

  return MAP[activeProductCode]
    ? MAP[activeProductCode][syntheticProductCode] || []
    : [];
};

export const getMaxSetOfProductsFromBankingBundle = ({ activeProductCode }) => {
  return getProductsFromBankingBundle({
    activeProductCode,
    syntheticProductCode: SYNTHETIC_PRODUCT_CODES.incOAAndEINAndBR,
  });
};

export const evaluatePackageTransitionChanges = ({
  fullSetOfProductsByCurrentProcessing = [],
  fullSetOfProductsByNextProcessing = [],
  currentSetOfProducts = [],
}) => {
  const sharedProducts = fullSetOfProductsByCurrentProcessing.reduce(
    (res, code) => {
      if (fullSetOfProductsByNextProcessing.includes(code)) {
        res.push(code);
      }

      return res;
    },
    []
  );

  const notIncludedProducts = sharedProducts.reduce((res, code) => {
    if (!currentSetOfProducts.includes(code)) {
      res.push(code);
    }

    return res;
  }, []);

  const includedProducts = sharedProducts.reduce((res, code) => {
    if (currentSetOfProducts.includes(code)) {
      res.push(code);
    }

    return res;
  }, []);

  return { notIncludedProducts, includedProducts };
};

export const sanitizeProducts = ({ processingCode, setOfProducts = [] }) => {
  if (processingCode === PROCESSING_CODES.diamond) {
    return union(
      setOfProducts.filter((code) => code !== PRODUCT_CODES.incRushProcessing),
      [PRODUCT_CODES.incSameDayProcessing]
    );
  } else {
    if (setOfProducts.includes(PRODUCT_CODES.incSameDayProcessing)) {
      return setOfProducts.filter(
        (code) => code !== PRODUCT_CODES.incRushProcessing
      );
    }

    if (setOfProducts.includes(PRODUCT_CODES.incRushProcessing)) {
      return setOfProducts;
    }

    if (processingCode === PROCESSING_CODES.platinum) {
      return union(setOfProducts, [PRODUCT_CODES.incRushProcessing]);
    }

    return setOfProducts;
  }
};
